import Vue from 'vue'
import VueRouter from 'vue-router'
import localCache from '@/utils/cache'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) return originalPush.call(this, location, resolve, reject)
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  
  {
    path: '/main',
    name: 'main',
    component: () => import('../views/main/main.vue'),
    children:[
      {
        path: '/main/produactionAnalysis',
        name: 'produactionAnalysis',
        component: () => import(/* webpackChunkName: "produactionAnalysis" */ '../views/rpa-report/produactionAnalysis.vue')
      },
    ]
  },
   
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // checkGPT 首页
  {
    path: '/checkIndex',
    name: 'checkIndex',
    component: () => import(/* webpackChunkName: "checkPage" */ '../views/checkIndex.vue')
  },

  {
    path: '/checkTextPage',
    name: 'checkTextPage',
    component: () => import(/* webpackChunkName: "checkTextPage" */ '../views/checkText/checkTextPage.vue')
  },

  {
    path: '/checkText',
    name: 'checkText',
    component: () => import(/* webpackChunkName: "checkText" */ '../views/checkText/checkText.vue')
  },
  {
    path: '/checkImagePage',
    name: 'checkImagePage',
    component: () => import(/* webpackChunkName: "checkImagePage" */ '../views/checkImage/checkImagePage.vue')
  },
  {
    path: '/checkImage',
    name: 'checkImage',
    component: () => import(/* webpackChunkName: "checkImage" */ '../views/checkImage/checkImage.vue')
  },
  // 聊天机器人
  {
    path: '/chatPage',
    name: 'chatPage',
    component: () => import(/* webpackChunkName: "chatPage" */ '../views/chatRobot/chatPage.vue')
  },

  {
    path: '/chatRobot',
    name: 'chatRobot',
    component: () => import(/* webpackChunkName: "chatRobot" */ '../views/chatRobot/chatRobot.vue')
  },
  // 提示词收集
  {
    path: '/promptCollection',
    name: 'promptCollection',
    component: () => import(/* webpackChunkName: "promptCollection" */ '../views/promptCollection/promptCollection.vue')
  },
  // 短视频生成 首页
  {
    path: '/shortVideoIndex',
    name: 'shortVideoIndex',
    component: () => import(/* webpackChunkName: "shortVideoIndex" */ '../views/shortVideoFactory/shortVideoIndex.vue')
  },
  // AI体育
  {
    path: '/AISportsIndex',
    name: 'AISportsIndex',
    component: () => import(/* webpackChunkName: "AISports" */ '../views/AISports/AISportsIndex.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // console.log('to', to, from)
  // if (to.path !== '/login') {
  //   const token = localCache.getCache('token')
  //   if (!token) {
  //     router.push('/login')
  //   }
  // }
  next()
})
export default router
