import router from '@/router'
import {
    accountLoginRequest
} from '@/service/login/login'
import localCache from '@/utils/cache'
import { Message } from 'element-ui'
const loginModule = {
    namespaced: true,
    state() {
        return {
            token: '',
            userInfo: {},
            userMenus: [],
        }
    },
    getters: {},
    mutations: {
        // 修改state的方式是通过mutation
        changeToken(state, token) {
            state.token = token
        },
        changeUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        changeUserMenus(state, userMenus) {
            state.userMenus = userMenus
            // userMenus => routes
            // 将routes => router.main.router
        }
    },
    actions: {
        async accountLoginAction({ commit }, payload) {

            // localCache.setCache('token', 123)
            // console.log('loginRes', loginRes)
            //   commit('changeToken', token)
            //   localCache.setCache('token', token)
            // localCache.setCache('token', 123)
            // let userInfo = {
            //     name: '张三'
            // }
            // localCache.setCache('userInfo', userInfo)
            // ========================================================
            // 1.实现登录逻辑
            const loginRes = await accountLoginRequest(payload)
            if (loginRes.code == 200) {
                Message({
                    type: 'success',
                    offset: 200,
                    message: '登录成功'
                })
                console.log('loginRes', loginRes)
                const { token } = loginRes.data
                // const token = '123'
                commit('changeToken', token)
                localCache.setCache('check_token', token)
                // 2.请求用户信息
                //   const userInfoRes = await requestUserInfoByid(id)
                //   const userInfo = userInfoRes.data
                //   commit('changeUserInfo', userInfo)
                //   localCache.setCache('userInfo', userInfo)
                // 3.请求用户菜单
                //   const userMenusRes = await requestUserMenusByRoleId(userInfo.role.id)
                //   const userMenus = userMenusRes.data
                //   commit('changeUserMenus', userMenus)
                //   localCache.setCache('userMenus', userMenus)
                // 4.跳转首页
                router.push('/home')
            }

            router.push('/home')

            return loginRes

        },
        loadLocalLogin({ commit }) {
            const token = localCache.getCache('check_token')
            if (token) {
                commit('changeToken', token)
            }
            const userInfo = localCache.getCache('userInfo')
            if (userInfo) {
                commit('changeUserInfo', userInfo)
            }
            const userMenus = localCache.getCache('userMenus')
            if (userMenus) {
                commit('changeUserMenus', userMenus)
            }
        },
        phoneLoginAction() {
            console.log('执行aciton', payload)
        }
    }
}
export default loginModule
