<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'app',
  data() { return {} },
  created() {
    // 当DOM加载完毕时，设置html元素的fontSize
    document.addEventListener('DOMContentLoaded', () => {
      const html = document.querySelector('html')
      let fontSize = window.innerWidth / 10
      fontSize = fontSize > 50 ? 50 : fontSize // 设置最大为50
      html.style.fontSize = fontSize + 'px'
    })
  }
}


</script>

<style lang="scss">
#app {
  background: #f6f6f6;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 2.5px;
  -webkit-box-shadow: inset 0 0 2.5px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2.5px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  background-color: rbga(144, 147, 153, 0.3);
  transition: background-color 0.3s;
}
::-webkit-scrollbar-track {
  border-radius: 2.5px;
  -webkit-box-shadow: inset 0 0 2.5px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 2.5px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
</style>
