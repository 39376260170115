// 类的封装性更强
import axios from 'axios'
import { Message } from 'element-ui'
/**
 *  axios实例
 */
import router from '@/router'
import localCache from '@/utils/cache'
import { Loading } from 'element-ui'
const DEFAULT_LOAIDNG = true
class CvRequest {
    // constructor(config: AxiosRequestConfig) {
    constructor (config) {
        this.instance = axios.create(config) // 保存当前实例，不同实例互不影响
        this.showLoadings = config.showLoadings
        /**
         * 每个实例【自己】的拦截器
         * 从config中取出的拦截器是对应的实例的拦截器
         */
        this.intercepetors = config.intercepetors
        this.instance.interceptors.request.use(
            this.intercepetors?.requestInterceptor,
            this.intercepetors?.requestInterceptorCatch
        )
        this.instance.interceptors.response.use(
            this.intercepetors?.responseInterceptor,
            this.intercepetors?.responseInterceptorCatch
        )
        // 添加【所有】的实例都有的拦截器
        this.instance.interceptors.request.use(
            (config) => {
                // console.log('公共-请求拦截成功')
                // console.log(config)
                if (this.showLoadings) {
                    this.loading = Loading.service({
                        lock: true,
                        text: '正在请求',
                        background: 'rgba(0, 0, 0, 0.5)'
                    })
                }
                return config
            },
            (err) => {
                this.showLoadings = DEFAULT_LOAIDNG
                // console.log('公共-请求拦截失败')
                return err
            }
        )
        this.instance.interceptors.response.use(
            (res) => {
                // console.log('res', res)
                this.loading?.close()
                if (!res.data) {
                    Message({
                        type: 'error',
                        message: '网络异常:err' + res.message,
                        duration: 2000,
                        offset: 200
                    })
                    return res.data
                }
                // console.log('公共-响应拦截成功')
                if (res.data.code == 200) {
                    // Message({
                    //     type: 'success',
                    //     message: res.data.msg,
                    //     duration: 3000,
                    //     offset: 200
                    // })
                } else if (res.data.code == 601) {
                    Message({
                        type: 'warning',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 200
                    })
                } else if (res.data.code == 620) {
                    Message({
                        type: 'error',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 200
                    })
                    localCache.clearCache()
                    // console.log('重新登录')
                    router.push('/login')
                } else if (res.data.type == 'audio/x-wav' || res.data.type == 'video/mp4') {
                    console.log(`获取${res.data.type}文件流成功`)
                } else if (res.data.code == 103) {
                    console.log('request index.js:', res.data)
                }  else if (res.data.code == 102) {
                    console.log('request 102 index.js:', res.data)
                    Message({
                        type: 'error',
                        message: '请先返回首页点击立即体验进行登录！',
                        duration: 2000,
                        offset: 200,
                        customClass: "messageClass"
                    })
                } else {
                    Message({
                        type: 'error',
                        message: res.data.msg,
                        duration: 2000,
                        offset: 200,
                        customClass: "messageClass"
                    })
                    // return Promise.reject(res)
                }
                return res.data
            },
            (err) => {
                this.loading?.close()
                console.log('公共-响应拦截失败')
                Message({
                    type: 'error',
                    message: '网络异常:err' + err,
                    duration: 2000,
                    offset: 200
                })
                return Promise.reject(err)
            }
        )
    }
    request(config) {
        return new Promise((resolve) => {
            if (config.showLoadings === false) {
                this.showLoadings = config.showLoadings
            }
            /**
             * request<T = any, R = AxiosResponse<T>, D = any>
             * AxiosResponse被修改， res => res.data
             * 所以这块的类型为传入的类型T
             */
            this.instance.request(config).then((res) => {
                // console.log(res)
                if (config.intercepetors?.responseInterceptor) {
                    res = config.intercepetors.responseInterceptor(res)
                }
                this.showLoadings = DEFAULT_LOAIDNG
                resolve(res)
            })
        })
    }
    get(config) {
        return this.request({ ...config, method: 'GET' })
    }
    post(config) {
        return this.request({ ...config, method: 'POST' })
    }
    delete(config) {
        return this.request({ ...config, method: 'DELETE' })
    }
    patch(config) {
        return this.request({ ...config, method: 'PATCH' })
    }
}
export default CvRequest
