import cvRequest from '../index'
import qs from 'qs'
const base = '/user'
export function accountLoginRequest(data) {
    return cvRequest.post({
        url: `${base}/login`,
        data: qs.stringify(data)
    })
}
export function editPwdRequest(data) {
    return cvRequest.post({
        url: `${base}/edit_pwd`,
        data: qs.stringify(data)
    })
}
// export function requestUserMenusByRoleId(id) {
//   return cvRequest.get({
//     url: '/role/' + id + '/menu',
//     showLoadings: false
//   })
// }
