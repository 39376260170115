/**
 * 开发 http://httpbin.org/dev
 * 生产 http://httpbin.org/prod
 *
 */
// 根据process.env.NODE_ENV
/**
 * 开发环境：development
 * 成产环境：production
 * 测试环境：test
 */
// let BASE_URL = ''
// const TIME_OUT = 10000
// console.log(process.env.NODE_ENV)
// if (process.env.NODE_ENV === 'development') {
//     // BASE_URL = 'http://httpbin.org/dev'
//     BASE_URL = 'http://192.168.162.10:8085'

//     //   BASE_URL = '/api'
// } else if (process.env.NODE_ENV === 'production') {
//     BASE_URL = 'http://192.168.162.10:8085'
// } else {
//     BASE_URL = 'http://192.168.162.10:8085'
// }
// export { BASE_URL, TIME_OUT }
// export const API_BASE_URL = process.env.VUE_APP_BASE_URL
export const API_BASE_URL = ''

export const TIME_OUT = 3*60*1000