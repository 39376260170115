class localCache {
    setCache(key, value) {
        window.sessionStorage.setItem(key, value)
    }
    getCache(key) {
        const value = window.sessionStorage.getItem(key)
        if (value) {
            return value
        }
    }
    deleteCache(key) {
        window.sessionStorage.removeItem(key)
    }
    // 全部清空
    clearCache() {
        window.sessionStorage.clear()
    }
}
export default new localCache()
