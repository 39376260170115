import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import './assets/scss/main.scss'
// import './assets/scss/edit.scss'
import './assets/scss/base.scss'
import './assets/styles/reset.css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '../src/assets/icon_font/iconfont.css'
import { accountLoginRequest } from '@/service/login/login'
import * as _filter from "@/utils/filters"
//引入swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// 引入swiper样式
import "swiper/css/swiper.css"
// 使用recorder
// import Recorder from 'recorder-core/recorder.mp3.min'
// Vue.use(Recorder)
//挂载swiper
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(ElementUI)
Object.keys(_filter).forEach(item => {
  Vue.filter(item, _filter[item])
})

Vue.prototype.$newUuid = function() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// const res = accountLoginRequest({ name: '123' })
// console.log('12313', res)