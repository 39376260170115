// serveice統一出口
import CvRequest from './request'
import { API_BASE_URL, TIME_OUT } from './request/config'
import localCache from '@/utils/cache'
console.log('process.env.VUE_APP_BASE_URL', API_BASE_URL)
const cvRequest = new CvRequest({
    // headers: {},
    showLoadings: true,
    baseURL: API_BASE_URL,
    timeout: TIME_OUT,
    // 代码的可扩展性
    intercepetors: {
        requestInterceptor: (config) => {
            // console.log('封装--请求成功的拦截', config.url.split('/'))
            const route = config.url.split('/')
            let token = ''
            if (route.length > 0 && route[1] == 'check_service') {
                token = localCache.getCache('check_token')
            } else if (route[1] == 'prompt_service') {
                token = localCache.getCache('prompt_token')
            } else if (route[2] == 'short_video_service') {
                token = localCache.getCache('short_video_token')
            } else if (route[1] == 'AI-sport-back-server') {
                token = localCache.getCache('AISports_token')
            }

            if (token) {
                config.headers.Authorization = `${token}`
            }
            return config
        },
        requestInterceptorCatch: (err) => {
            // console.log('封装--请求失败的拦截')
            return err
        },
        responseInterceptor: (res) => {
            // console.log('封装--响应成功的拦截', res)

            return res
        },
        responseInterceptorCatch: (err) => {
            console.log('封装--响应失败的拦截', err)
            // return Promise.reject(err)
            return err
        }
    }
})
export default cvRequest
